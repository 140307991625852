<template>
  <layout-loader-view>
    <div id="login" class="full-size-height flex-centered">
      <v-card>
        <v-card-title class="justify-center my-0">
          {{ texts.title }}
        </v-card-title>

        <v-divider />
        <v-form @keydown.native.enter="login">
          <v-row justify="center" class="pt-4 pb-0">
              <v-text-field
                  class="mx-16"
                  :placeholder="texts.email.placeholder"
                  v-model="email"
                  prepend-inner-icon="mdi-at"
              />
          </v-row>
          <v-row justify="center" class="py-0 my-0">
            <v-text-field
                class="mx-16 my-0"
                :placeholder="texts.password.placeholder"
                v-model="password"
                type="password"
                prepend-inner-icon="mdi-lock"
            />
          </v-row>
          <v-row justify="center" class="pt-0 mt-2">
            <v-btn
                class="text-none px-8"
                color="primary"
                @click="login"
            >
              {{ texts.button.text }}
            </v-btn>
          </v-row>
        </v-form>

        <v-row class="mt-8 mb-2">
          <global-logo light></global-logo>
        </v-row>

      </v-card>
    </div>
  </layout-loader-view>
</template>

<script>
import {mapActions, mapMutations} from 'vuex'
import GlobalLogo from "@/components/global/GlobalLogo";

export default {
  name: "Login",
  components: {
    GlobalLogo
  },
  data: () => ({
    email: '',
    password: '',
    texts: {
      title: 'Log in to admin panel',
      email: {
        placeholder: 'Email'
      },
      password: {
        placeholder: 'Password'
      },
      button: {
        text: 'Log in'
      }
    }
  }),
  mounted() {
    this.setLoader({visible: false})
  },
  methods: {
    ...mapActions('authentication', {loginAction: 'login'}),
    ...mapMutations('components', ['setLoader']),
    async login() {
      const {email, password} = this
      await this.loginAction({
        email, password, next: () => {
          this.$router.push('/dashboard')
        }
      })
    }
  }
}
</script>